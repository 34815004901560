
document.addEventListener("DOMContentLoaded", function () {
  setTimeout(iniciar, 2500);
});

document.addEventListener("scroll", initOnEvent);
document.addEventListener("mousemove", initOnEvent);
document.addEventListener("touchstart", initOnEvent);

function initOnEvent(event) {
  iniciar();
  event.currentTarget.removeEventListener(event.type, initOnEvent); // remove the event listener that got triggered
}
  // iniciar();

function initGtm(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
}

async function initPixel(f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);

  fbq("init", "294939673456021");
      // fbq("track", "PageView");

}

function iniciar() {
  if (window.DidInit) {
    return false;
  }
  window.dispatchEvent(new Event("resize"));
  
  window.DidInit = true;

  try {
    initClarity(window, document, "clarity", "script", "pw0wpz7cce");
  } catch {
    console.log("Erro no initClarity");
  }

  try {
    initAnalytics();
  } catch {
    console.log("Erro no analytics");
  }

  // try {
  //   initPixel(
  //     window,
  //     document,
  //     "script",
  //     "https://connect.facebook.net/en_US/fbevents.js"
  //   );
  //   if (window.location.href.search("/obrigado") > -1) {
  //     console.log("LEAD");
  //     fbq("track", "Lead");
  //   } else {
  //     console.log("VIEW");
  //     fbq("track", "PageView");
  //   }
  // } catch {
  //   console.log("Erro ao iniciar o pixel");
  // }

  try {
    initTiktok(window, document, "ttq");
    if (window.location.href.search("/obrigado") > -1) {
      ttq.identify({
        email: "<hashed_email_address>", // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
        phone_number: "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
        external_id: "<hashed_extenal_id>", // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
      });

      ttq.track("Subscribe", {});
    } else {
      console.log("VIEW");
    }
  } catch {
    console.log("Erro no tiktok");
  }

  initGtm(window, document, "script", "dataLayer", "GTM-WMNBD67Z");
}

function initAnalytics() {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "G-D7FBM50LQ8");
}

function initTiktok(w, d, t) {
  w.TiktokAnalyticsObject = t;
  var ttq = (w[t] = w[t] || []);
  (ttq.methods = [
    "page",
    "track",
    "identify",
    "instances",
    "debug",
    "on",
    "off",
    "once",
    "ready",
    "alias",
    "group",
    "enableCookie",
    "disableCookie",
    "holdConsent",
    "revokeConsent",
    "grantConsent",
  ]),
    (ttq.setAndDefer = function (t, e) {
      t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
      };
    });
  for (var i = 0; i < ttq.methods.length; i++)
    ttq.setAndDefer(ttq, ttq.methods[i]);
  (ttq.instance = function (t) {
    for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
      ttq.setAndDefer(e, ttq.methods[n]);
    return e;
  }),
    (ttq.load = function (e, n) {
      var r = "https://analytics.tiktok.com/i18n/pixel/events.js",
        o = n && n.partner;
      (ttq._i = ttq._i || {}),
        (ttq._i[e] = []),
        (ttq._i[e]._u = r),
        (ttq._t = ttq._t || {}),
        (ttq._t[e] = +new Date()),
        (ttq._o = ttq._o || {}),
        (ttq._o[e] = n || {});
      n = document.createElement("script");
      (n.type = "text/javascript"),
        (n.async = !0),
        (n.src = r + "?sdkid=" + e + "&lib=" + t);
      e = document.getElementsByTagName("script")[0];
      e.parentNode.insertBefore(n, e);
    });

  ttq.load("CT0DSFBC77U74JDQOD20");
  ttq.page();
}

function initClarity(c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = "https://www.clarity.ms/tag/" + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
}

// add this before event code to all pages where PII data postback is expected and appropriate
